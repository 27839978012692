import { css, ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import React, { memo } from 'react'

import { MediaContextProvider } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      primaryLight: '#FFFFFF',
      neutralLight1: '#FFFBF6',
      neutralLight2: '#F7F2ED',
      neutralLight3: '#FFEABC',
      neutralLight4: '#F5AB74',
      neutralLight5: '#FCF9F2',
      neutralDark1: '#3B3033',
      primaryDark: '#242424',
      secondaryDark: '#9B1B1C',
      danger: '#ED0F36',
    },
  },
  fontFamily: {
    heading: "'Sofia Sans Extra Condensed', sans-serif",
    paragraph: "'Merriweather', serif",
    label: "'Sofia Sans Extra Condensed', sans-serif",
  },
}

export type Styles = typeof styles

export const styles = {
  h1: css`
    color: ${theme.colors.variants.primaryDark};
    font-family: ${theme.fontFamily.heading};
    font-weight: normal;
    font-size: 5.31rem;

    @media (max-width: 1199px) {
      font-size: 3.125rem;
    }
  `,
  h2: css`
    color: ${theme.colors.variants.primaryDark};
    font-family: ${theme.fontFamily.heading};
    font-weight: normal;
    font-size: 3.75rem;
    text-transform: uppercase;
    letter-spacing: 0.15rem;

    @media (max-width: 1199px) {
      font-size: 1.875rem;
      line-height: 2.5rem;
    }
  `,
  h3: css`
    color: ${theme.colors.variants.primaryDark};
    font-family: ${theme.fontFamily.heading};
    font-weight: normal;
    font-size: 1.875rem;
    line-height: 2.5rem;
  `,
  label: css`
    display: flex;
    align-items: center;
    margin-bottom: 2.5rem;
    color: ${theme.colors.variants.primaryDark};
    font-family: ${theme.fontFamily.label};
    font-size: 1.06rem;
    letter-spacing: 0.2em;
    line-height: 1.125rem;
    text-align: left;
    text-transform: uppercase;

    &::before {
      content: '';
      display: block;
      width: 9.25rem;
      height: 0.0625rem;
      margin-right: 0.8125rem;
      background: ${theme.colors.variants.secondaryDark};

      @media (max-width: 1199px) {
        width: 3.125rem;
      }
    }
  `,
  description: css`
    color: ${theme.colors.variants.primaryDark};
    font-family: ${theme.fontFamily.paragraph};
    font-weight: normal;
    font-size: 1rem;
    line-height: 2.1rem;

    a {
      font-weight: 600;
      text-decoration: underline;
    }

    @media (max-width: 1199px) {
      font-size: 1rem;
      line-height: 1.6875rem;
    }
  `,
}

export const ThemeProvider = memo(function ThemeProvider({ children }: any) {
  return (
    <EmotionThemeProvider theme={theme}>
      <MediaContextProvider>{children}</MediaContextProvider>
    </EmotionThemeProvider>
  )
})
